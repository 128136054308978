<!-- 优惠券 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">优惠券列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="VipApi.getGiftLists" :columns="tableColumns" @edit="openDialog"
        @statusChange="changeStatus">
        <template #operate>
          <el-button type="primary" round @click="openDialog"
            v-if="authData.indexOf('m_IQ0FyAqh7KQa5hmdd0iiJpEyfEq6') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增优惠券
          </el-button>
        </template>
      </common-table>
    </div>

    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.g_id ? '编辑优惠券' : '新增优惠券'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.g_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="优惠券名称" prop="g_name">
              <el-input v-model="ruleForm.g_name" clearable placeholder="请输入优惠券名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="园区" prop="g_sid">
              <el-select v-model="ruleForm.g_sid" placeholder="请选择园区" clearable :disabled="ruleForm.g_id ? true : false"
                @change="parkChange">
                <el-option v-for="el in parkOptions" :key="el.s_id" :label="el.s_name" :value="el.s_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="类型" prop="g_gtid">
              <el-select v-model="ruleForm.g_gtid" placeholder="请选择类型" clearable
                :disabled="ruleForm.g_id ? true : false">
                <el-option v-for="el in typesOptions" :key="el.id" :label="el.name" :value="el.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="g_send_type" label="发放类型">
              <el-select v-model="ruleForm.g_send_type" placeholder="请选择类型" clearable>
                <el-option v-for="el in distribuTypeOptions" :key="el.id" :label="el.name" :value="el.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="g_goods_id" label="商品">
              <el-select v-model="ruleForm.g_goods_id" filterable placeholder="请选择商品" clearable>
                <el-option v-for="el in commodityOptions" :key="el.id" :label="el.name" :value="el.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="使用说明" prop="g_desc">
          <el-input v-model="ruleForm.g_desc" :rows="5" type="textarea" placeholder="请输入使用说明" />
        </el-form-item>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch } from "vue";
  import { VipApi, BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { checkStr } from "@/utils/common.js";
  import { useStore } from "vuex";

  onMounted(() => {
    getParkDatas();
    getCouponDatas();
    getGiftSendTypeLists();
    tableRef.value.tableLoad();
  });
  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const tableRef = ref(null);  // 表格对象
  const parkOptions = ref([]);  // 园区选项
  const typesOptions = ref([]);  // 类型选项
  const distribuTypeOptions = ref([]);  // 发放类型选项
  const commodityOptions = ref([]);  // 商品选项
  const dialogRef = ref(null);
  const formRef = ref(null);
  const ruleForm = ref({
    g_name: "",  // 名称
    g_sid: '',  // 园区id
    g_gtid: '',  // 类型
    g_send_type: '',  // 发放类型
    g_goods_id: '',  // 商品
    g_desc: "",  // 使用说明
  });
  const rules = reactive({
    g_name: [{ required: true, message: "请输入优惠券名", trigger: "blur", },],
    g_sid: [{ required: true, message: "请选择园区", trigger: "blur", },],
    g_gtid: [{ required: true, message: "请选择类型", trigger: "blur", },],
    g_send_type: [{ required: true, message: "请选择发放类型", trigger: "blur", },],
  });
  const filters = ref([  // 筛选条件
    {
      filterType: "select",
      name: "s_id",
      value: "",
      placeholder: "请选择园区",
      options: [],
      val: "s_id",
      lab: "s_name",
    },
    {
      filterType: "select",
      name: "gt_id",
      value: "",
      placeholder: "请选择活动类型",
      options: [],
      val: "id",
      lab: "name",
    },
  ]);
  const tableColumns = ref([  // 表格配置数据
    {
      prop: "g_name",
      label: "名称",
    },
    {
      prop: "s_name",
      label: "园区",
    },
    {
      prop: "gt_name",
      label: "类型",
    },
    {
      prop: "gift_send_type_text",
      label: "发放类型",
    },
    {
      prop: "goods_name",
      label: "关联商品",
    },
    {
      prop: "goods_stock",
      label: "商品库存",
    },
    {
      prop: "g_desc",
      label: "使用说明",
    },
    {
      type: "switch",
      prop: "g_status",
      label: "状态",
      minWidth: 80,
      token: "n_DBf28wLLnQ2STQT9uOotiNh3dkZ4",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "m_xoyKUTywna3q4TurdttbuYRYOoBk",
        },
      ],
    },
  ]);
  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /**
  *
  * 获取园区选项
  *
  * */
  const getParkDatas = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        parkOptions.value = res.Data;
        filters.value[0].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取优惠券类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取类型选项
  *
  * */
  const getCouponDatas = () => {
    VipApi.getGiftTypeLists({}).then((res) => {
      if (res.Code === 200) {
        typesOptions.value = res.Data;
        filters.value[1].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取优惠券类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取发放类型选项
  *
  * */
  const getGiftSendTypeLists = () => {
    VipApi.getGiftSendTypeLists().then((res) => {
      if (res.Code === 200) {
        distribuTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取发放类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取商品选项
  *
  * */
  const parkChange = () => {
    commodityOptions.value = [];
    ruleForm.value.g_goods_id = '';
    if (!ruleForm.value.g_sid) {
      return false;
    }
    getGoodsLists();
  }
  const getGoodsLists = () => {
    VipApi.getGoodsLists({ s_id: ruleForm.value.g_sid }).then((res) => {
      if (res.Code === 200) {
        commodityOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取商品失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 新增、编辑按钮
  *
  * */
  const openDialog = (row) => {
    if (row.g_id) {
      //编辑
      const data = JSON.parse(JSON.stringify(row));
      ruleForm.value = data;
      getGoodsLists();
      ruleForm.value.g_goods_id = data.g_goods_id == 0 ? '' : data.g_goods_id;
    } else {
      // 新增
      ruleForm.value = {
        g_name: '',
        g_sid: '',
        g_gtid: '',
        g_send_type: '',
        g_goods_id: '',
        g_desc: '',
      };
    }
    dialogRef.value.show();
  };
  /**
  *
  * 新增/编辑 确认提交
  *
  * */
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        dialogRef.value.isLoading = true;
        const data = JSON.parse(JSON.stringify(ruleForm.value))

        let parmas = {
          g_name: data.g_name,
          g_sid: data.g_sid,
          g_gtid: data.g_gtid,
          g_send_type: data.g_send_type,
          g_goods_id: data.g_goods_id,
          g_desc: data.g_desc,
        };
        let url = "";
        if (data.g_id) {
          // 编辑
          url = "updateGift";
          parmas["g_id"] = data.g_id;
        } else {
          // 新增
          url = "addGift";
        }
        VipApi[url](parmas).then((res) => {
          dialogRef.value.isLoading = false;
          const text = data.g_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`优惠券${text}成功！`);
            dialogRef.value.close();
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `优惠券${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };
  /**
  *
  * 修改状态
  *
  * */
  const changeStatus = (row) => {
    let data = {
      g_id: row.g_id,
      g_status: row.g_status == 1 ? 2 : 1,
    };
    VipApi.changeStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success(row.a_status == 1 ? "关闭成功！" : "开启成功！");
        tableRef.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
</script>
<style lang="scss">
  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>